import { formatData } from '@utilities';

/**
 * transformColumn - capitalizes Header for FE table purposes
 * @param {Object} column
 * @param {String} column.accessor
 * @param {Number|String} [column.footer]
 * @param {String} column.header
 * @return {Object}
 */
const transformColumn = ({ accessor, footer, header }) => ({
  accessor,
  ...(footer && { Footer: footer }),
  Header: header,
});

/**
 * transformEssayResponses
 * @param {Object} response
 * @param {Object[]} response.data
 * @return {Object[]}
 */
export const transformEssayResponses = ({ data }) =>
  data.map(({ id, segments, shortname, ...question }) => ({
    id: Number(id),
    ...question,
    segments: segments.map(({ table, ...segment }) => ({
      ...segment,
      table: {
        columns: table.columns.map(transformColumn),
        data: table.data,
      },
    })),
    shortName: Number(shortname),
  }));

/**
 * transformGlobalFilters
 * @param {Object} filters
 * @param {Object[]} filters.demographic_filters
 * @return {Object}
 */
export const transformGlobalFilters = ({ demographic_filters }) => ({
  demographicFilters: demographic_filters.map(({ compare_by: compareBy, id, options, title }) => ({
    compareBy,
    id,
    label: title,
    options,
  })),
});

/**
 * transformRawData
 * @param {Object} response
 * @param {Object[]} response.data
 * @return {Object[]}
 */
export const transformRawData = ({ data }) =>
  data.map(({ table }) => ({
    table: {
      columns: table.columns.map(transformColumn),
      data: table.data,
    },
  }));

/**
 * transformSurveyResponses
 * @param {Object} response
 * @param {Object[]} response.data
 * @return {Object[]}
 */
export const transformSurveyResponses = ({ data }) =>
  data.map(
    ({ chart_count, chart_pct, id, shortname, table_count, table_other, table_pct, type }) => {
      return {
        chartCount: {
          ...chart_count,
          series: chart_count.series.map((series) => ({
            ...series,
            dataLabels: {
              formatter: function () {
                return formatData({ format: chart_count.format, value: this.y });
              },
            },
          })),
        },
        chartPct: {
          ...chart_pct,
          series: chart_pct.series.map((series) => ({
            ...series,
            dataLabels: {
              formatter: function () {
                return formatData({ format: chart_pct.format, value: this.y });
              },
            },
          })),
        },
        id: Number(id),
        shortName: Number(shortname),
        tableCount: {
          columns: table_count.columns.map(transformColumn),
          data: table_count.data,
        },
        tableOther: {
          columns: table_other?.columns.map(transformColumn),
          data: table_other?.data.map(({ data, id, name: text }) => ({
            id,
            subRows: data.sort(
              ({ text: textA }, { text: textB }) =>
                textB.length - textA.length || textA.localeCompare(textB)
            ),
            text,
          })),
        },
        tablePct: {
          columns: table_pct.columns.map(transformColumn),
          data: table_pct.data.map((row) => {
            const formattedData = table_pct.columns
              .slice(1)
              .map(({ accessor }) => accessor)
              .reduce(
                (acc, accessor) => ({
                  ...acc,
                  [accessor]: formatData({ format: '0.0%', value: row[accessor] }),
                }),
                {}
              );

            return {
              ...row,
              ...formattedData,
            };
          }),
        },
        type,
      };
    }
  );
