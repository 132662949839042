import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useGate } from 'statsig-react';

import { AxiosError } from 'axios';

import {
  readExternalQuestions,
  readInstantSurvey,
  readInstantSurveyType,
} from '@api/instant_survey';
import {
  exportRawData,
  exportSummaryData,
  exportStatXtabData,
  readGlobalFilters,
  readRawData,
} from '@api/survey_dashboard';
import { readSurvey, readSurveyType, surveyStatus } from '@api/surveys';
import { GATES } from '@constants';

import {
  Breadcrumbs,
  DownloadIcon,
  Error,
  getFormattedDate,
  IconButton,
  LoaderSkeleton,
  OverlayPopover,
  Tabs,
  toast,
  Toolbar,
} from '@utilities';

import Details from './components/Details';
import InsightsDetails from './components/InsightsDetails';
import RawData from './components/RawData';
import Responses from './components/Responses';

const Survey = () => {
  const [globalFilters, setGlobalFilters] = useState();
  const [globalFiltersError, setGlobalFiltersError] = useState();
  const [insightsDocument, setInsightsDocument] = useState();
  const [insightsType, setInsightsType] = useState();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isRawDataLoading, setIsRawDataLoading] = useState(true);
  const [isSurveyResponsesLoading, setIsSurveyResponsesLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [questionFilters, setQuestionFilters] = useState();
  const [questionFiltersError, setQuestionFiltersError] = useState();
  const [rawData, setRawData] = useState();
  const [rawDataLoadingError, setRawDataLoadingError] = useState();
  const [survey, setSurvey] = useState();
  const [surveyType, setSurveyType] = useState();

  const downloadButtonRef = useRef();
  const { value: canQuestionFilter, isLoading: isStatsigLoading } = useGate(
    GATES.SURVEY_DASHBOARD_QUESTION_FILTERS
  );
  const { value: canCrosstabsExport } = useGate(GATES.CROSSTABS_EXPORT);

  const dataRequestController = useRef();
  const filtersRequestController = useRef();
  const rawDataRequestController = useRef();

  const navigate = useNavigate();
  const { routes } = useSelector((state) => state?.user?.data);
  const instantSurveyRoutes = routes?.find(({ id }) => id === 'instant-survey-reports')?.routes;

  let { survey_id: surveyId, tab_id: tabId } = useParams();

  useEffect(() => {
    if (!isStatsigLoading) {
      if (!tabId) {
        navigate(`/dashboard/survey/${surveyId}/${instantSurveyRoutes[0].id}`, { replace: true });
      }
      getData();
    }
    return dataRequestController?.current?.abort();
  }, [isStatsigLoading, surveyId]);

  useEffect(() => {
    if (insightsDocument?.id) {
      getFilters();
      getRawData();
    }
    return () => {
      filtersRequestController?.current?.abort();
      rawDataRequestController?.current?.abort();
    };
  }, [insightsDocument]);

  const getData = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const insightsSurvey = await readSurvey({ id: surveyId, signal });
      const validStatuses = [surveyStatus.COMPLETED, surveyStatus.MET_SAMPLE];
      if (!validStatuses.includes(insightsSurvey.status)) {
        throw new AxiosError('Request failed with status code 404', 'ERR_BAD_REQUEST', null, null, {
          status: 404,
        });
      }
      setInsightsDocument(insightsSurvey);
      if (insightsSurvey.atlasInstantSurveyRef) {
        const atlasRequests = [
          readInstantSurvey({
            id: insightsSurvey.atlasInstantSurveyRef,
            params: { include_response_count: true },
            signal,
          }),
          readInstantSurveyType({
            id: insightsSurvey.instantSurveyTypeId,
            signal,
          }),
        ];
        const [instantSurveyResponse, instantSurveyTypeResponse] = await Promise.all(atlasRequests);
        const { groups, ...instantSurvey } = instantSurveyResponse;
        setSurvey({
          ...instantSurvey,
          // sorting groups here so it never impacts a draft survey
          groups: groups.sort((a, b) => a.quotaGroupRef - b.quotaGroupRef),
        });
        setSurveyType(instantSurveyTypeResponse);
      } else {
        setInsightsType(
          await readSurveyType({
            documentId: insightsSurvey.instantSurveyTypeId,
            signal,
          })
        );
      }
      setIsDataLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        console.error(error);
        setLoadingError(error);
      }
    }
  };

  const getFilters = async () => {
    const controller = new AbortController();
    filtersRequestController.current = controller;

    setIsFiltersLoading(true);
    try {
      setGlobalFilters(
        await readGlobalFilters({
          signal: controller.signal,
          surveyId: insightsDocument?.id,
        })
      );

      if (canQuestionFilter) {
        try {
          setQuestionFilters(
            await readExternalQuestions({
              signal: controller.signal,
              surveySourceId: insightsDocument?.surveySourceId,
            })
          );
        } catch (error) {
          if (!controller.signal.aborted) {
            setQuestionFiltersError(error);
          }
        }
      }

      setIsFiltersLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setGlobalFiltersError(error);
      }
    }

    setIsFiltersLoading(false);
  };

  const getRawData = async () => {
    const controller = new AbortController();
    rawDataRequestController.current = controller;
    setIsRawDataLoading(true);

    try {
      const response = await readRawData({ signal: controller.signal, surveyId });
      setRawData(response);
      setIsRawDataLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setRawDataLoadingError(error);
      }
    }
  };

  const handleExportDownload = async (exportDownloadAPI) => {
    try {
      await exportDownloadAPI({ surveyId });
    } catch (error) {
      console.error('Error occurred while exporting data', error);
      toast('Error occurred while exporting data.', { status: 'error' });
    }
  };

  const isDownloadDisabled = isSurveyResponsesLoading || isRawDataLoading;
  const tooltipText = isDownloadDisabled
    ? 'Download will be available after report has finished processing'
    : 'Download data';

  return (
    <div>
      <Breadcrumbs />
      {loadingError && <Error status={loadingError?.response?.status} />}
      {!loadingError && isDataLoading && (
        <LoaderSkeleton height={725}>
          <rect x="0" y="0" rx="4" ry="4" width="850" height="95" />
          <rect x="0" y="105" rx="4" ry="4" width="1333" height="40" />
          <rect x="0" y="155" rx="4" ry="4" width="300" height="30" />
          <rect x="0" y="195" rx="4" ry="4" width="1333" height="165" />
          <rect x="0" y="380" rx="4" ry="4" width="300" height="30" />
          <rect x="0" y="420" rx="4" ry="4" width="1333" height="58" />
          <rect x="0" y="488" rx="4" ry="4" width="1333" height="58" />
          <rect x="0" y="556" rx="4" ry="4" width="1333" height="58" />
        </LoaderSkeleton>
      )}

      {!loadingError && !isDataLoading && (
        <>
          <div>
            <Toolbar
              tools={[
                <IconButton
                  isDisabled={isDownloadDisabled}
                  onClick={() => setIsPopoverOpen(true)}
                  ref={downloadButtonRef}
                  tooltip={tooltipText}
                >
                  <DownloadIcon></DownloadIcon>
                </IconButton>,
                <OverlayPopover
                  isActive={isPopoverOpen}
                  onHide={() => setIsPopoverOpen(false)}
                  target={downloadButtonRef?.current}
                >
                  <ul>
                    <li>
                      <button onClick={() => handleExportDownload(exportSummaryData)}>
                        <span>Summary Tables</span>
                      </button>
                    </li>
                    <li>
                      <button onClick={() => handleExportDownload(exportRawData)}>
                        <span>Raw Data</span>
                      </button>
                    </li>
                    {canCrosstabsExport && (
                      <li>
                        <button onClick={() => handleExportDownload(exportStatXtabData)}>
                          <span>Crosstabs & Stat Testing</span>
                        </button>
                      </li>
                    )}
                  </ul>
                </OverlayPopover>,
              ]}
            >
              <h2>{survey?.title || insightsDocument.title}</h2>
            </Toolbar>
            <div>
              {survey && (
                <>
                  {survey.issueDateTime && (
                    <p>
                      Survey fielded on{' '}
                      <strong>{getFormattedDate(new Date(survey.issueDateTime))}</strong>
                    </p>
                  )}
                  {survey.groups.length > 1 && (
                    <p>
                      <strong>{survey.groups.length}</strong> quota groups
                    </p>
                  )}
                  {survey.responseCount && (
                    <p>
                      <strong>{survey.responseCount}</strong> total responses
                    </p>
                  )}
                  <p>
                    <strong>{survey.questions.length}</strong>{' '}
                    {survey.questions.length === 1 ? 'question' : 'questions'}
                  </p>
                </>
              )}
              {!survey && (
                <>
                  <p>
                    Survey created on{' '}
                    <strong>
                      {insightsDocument.created_datetime
                        ? getFormattedDate(new Date(insightsDocument.created_datetime))
                        : 'N/A'}
                    </strong>
                  </p>
                  {insightsDocument.completed && (
                    <p>
                      <strong>{insightsDocument.completed}</strong> responses
                    </p>
                  )}
                  <p>
                    <strong>{insightsDocument.questions.length}</strong>{' '}
                    {insightsDocument.questions.length === 1 ? 'question' : 'questions'}
                  </p>
                </>
              )}
            </div>
            <hr />
          </div>

          <Tabs
            routes={[
              { label: 'Survey Details', id: 'details' },
              { label: 'Survey Responses', id: 'responses' },
              { label: 'Raw Data', id: 'raw-data' },
            ].map(({ id, label }) => ({ label, to: `/dashboard/survey/${surveyId}/${id}` }))}
          />
          {tabId === 'details' && survey && <Details survey={survey} surveyType={surveyType} />}
          {tabId === 'details' && !survey && (
            <InsightsDetails survey={insightsDocument} surveyType={insightsType} />
          )}
          {tabId === 'responses' && (
            <Responses
              globalFilters={globalFilters}
              globalFiltersError={globalFiltersError}
              isFiltersLoading={isFiltersLoading}
              questionFilters={questionFilters}
              questionFiltersError={questionFiltersError}
              setSurveyResponseLoading={setIsSurveyResponsesLoading}
              survey={survey || insightsDocument}
            />
          )}
          {tabId === 'raw-data' && (
            <RawData
              isRawDataLoading={isRawDataLoading}
              rawData={rawData}
              rawDataLoadingError={rawDataLoadingError}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Survey;
