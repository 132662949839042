import PropTypes from 'prop-types';

import { QUESTION_TYPES } from '@api/instant_survey';
import { getQuestionIcon } from '@helpers';
import { Card, DEFAULT_CHART_COLORS, Layout, Pill, ProgressBar } from '@utilities';

import GroupAdvancedPrompts from '../../../CreateInstantSurvey/components/Panelists/components/QuotaGroupCard/components/GroupAdvancedPrompts';
import GroupMadlib from '../../../CreateInstantSurvey/components/Panelists/components/QuotaGroupCard/components/GroupMadlib';

import styles from './_index.module.scss';

const Details = ({ survey, surveyType }) => {
  return (
    <div className={styles['details']}>
      <h3>Quota {survey.groups.length > 1 ? 'Groups' : 'Group'}</h3>
      <Layout.Flex.Column className={styles['details-list']}>
        {survey.groups.map((group, index) => (
          <Card className={styles['details-card']} key={group.id} status="info">
            {/* <EditableTitle value={group.name} /> */}
            <h2>
              <span
                className={styles['details-group-color']}
                style={{ background: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length] }}
              />
              {group.name}
            </h2>
            <Layout.Flex gap="x-large">
              <Layout.Fill>
                <GroupMadlib group={group} isReadOnly surveyType={surveyType} />
                {surveyType.advancedPrompts?.length > 0 && (
                  <GroupAdvancedPrompts
                    advancedPrompts={surveyType.advancedPrompts}
                    group={group}
                    isCompleted
                  />
                )}
              </Layout.Fill>
              <div className={styles['details-group-divider']} />
              <Layout.Sidebar>
                <div
                  className={styles['details-group-response-counts']}
                  id={`response-counts-${group.id}`}
                >
                  Requested Quota <strong>{group.quotaLimit}</strong>
                </div>
                <ProgressBar
                  aria-labelledby={`response-counts-${group.id}`}
                  max={group.quotaLimit}
                  value={group.responseCount}
                />
              </Layout.Sidebar>
            </Layout.Flex>
          </Card>
        ))}
      </Layout.Flex.Column>

      <h3>Questionnaire</h3>
      <Layout.Flex.Column className={styles['details-list']} gap="small">
        {survey.questions.map((question, index) => (
          <Layout.Flex
            as={Card}
            className={styles['details-question']}
            elevation={1}
            key={question.id}
            padding="small"
          >
            <strong>{index + 1}</strong>
            <Layout.Fill>{question.text}</Layout.Fill>
            <Pill
              icon={getQuestionIcon(question)}
              isCompleted
              value={QUESTION_TYPES[question.type]?.name}
            />
          </Layout.Flex>
        ))}
        {survey.questions.length === 0 && <p>No questions are assigned to this survey.</p>}
      </Layout.Flex.Column>
    </div>
  );
};

Details.propTypes = {
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default Details;
