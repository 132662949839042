import PropTypes from 'prop-types';

import { QUESTION_TYPES } from '@api/instant_survey';
import { getQuestionIcon } from '@helpers';
import { Card, Layout, Pill, ProgressBar } from '@utilities';

import AdvancedPrompts from '../../../../components/Prompts/components/AdvancedPrompts';
import Madlibs from '../../../../components/Prompts/components/Madlibs';

import styles from '../Details/_index.module.scss';

const InsightsDetails = ({ survey, surveyType }) => {
  return (
    <div className={styles['details']}>
      <Layout.Flex.Column className={styles['details-list']}>
        {survey.groups.map((group) => (
          <Card className={styles['details-card']} key={group.id} status="info">
            <h2>{group.name}</h2>
            <Layout.Flex gap="x-large">
              <Layout.Fill>
                <Madlibs
                  answers={group.answers}
                  isCompleted
                  madlib={surveyType?.prompts_madlib}
                  madlibAdditional={surveyType?.prompts_madlib_additional}
                  madlibAdditionalLimit={surveyType?.prompts_madlib_additional_limit}
                  prompts={surveyType.prompts}
                />
                {surveyType.advancedPrompts?.length > 0 && (
                  <AdvancedPrompts
                    advancedPrompts={surveyType.advancedPrompts}
                    answers={group.answers}
                    isCompleted
                  />
                )}
              </Layout.Fill>
              <div className={styles['details-group-divider']} />
              <Layout.Sidebar>
                <div
                  className={styles['details-group-response-counts']}
                  id={`response-counts-${group.id}`}
                >
                  Requested Quota <strong>{group.responseCount}</strong>
                </div>
                <ProgressBar
                  aria-labelledby={`response-counts-${group.id}`}
                  max={group.responseCount}
                  value={group.issued || 0}
                />
              </Layout.Sidebar>
            </Layout.Flex>
          </Card>
        ))}
      </Layout.Flex.Column>

      <h3>Questionnaire</h3>
      <Layout.Flex.Column className={styles['details-list']} gap="small">
        {survey.questions.map((question, index) => (
          <Layout.Flex
            as={Card}
            className={styles['details-question']}
            elevation={1}
            key={question.id}
            padding="small"
          >
            <strong>{index + 1}</strong>
            <Layout.Fill>{question.text}</Layout.Fill>
            <Pill
              icon={getQuestionIcon(question)}
              isCompleted
              value={QUESTION_TYPES[question.type]?.name}
            />
          </Layout.Flex>
        ))}
        {survey.questions.length === 0 && <p>No questions are assigned to this survey.</p>}
      </Layout.Flex.Column>
    </div>
  );
};

InsightsDetails.propTypes = {
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default InsightsDetails;
