import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, isMaxNameLength, ModalV2 as Modal, NewFolderIcon } from '@utilities';

import styles from './_index.module.scss';

const CreateFolder = ({ createFolder, onSubmit }) => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await createFolder({ name });
      onSubmit();
      setIsActive(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setName('');
    setIsLoading(false);
  }, [isActive]);

  return (
    <>
      <Button
        data-testid="create-folder-button"
        onClick={() => setIsActive(true)}
        text={
          <>
            <NewFolderIcon /> Create Folder
          </>
        }
        variant="secondary"
      />
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            key="submit-btn"
            isDisabled={name.trim().length === 0 || isMaxNameLength(name)}
            isLoading={isLoading}
            onClick={handleSubmit}
            text="Save"
          />,
        ]}
        className={styles['create-folder']}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        title="Create Folder"
      >
        <Input
          error={
            isMaxNameLength(name)
              ? 'Maximum number of characters reached. Try something shorter.'
              : ''
          }
          label="Folder Name"
          onChange={setName}
          placeholder="Enter name"
          value={name}
        />
      </Modal>
    </>
  );
};

CreateFolder.propTypes = {
  createFolder: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CreateFolder;
